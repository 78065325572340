/*
* Mainly scss variables
*/

/* --- ↓ width and height ---- */

$tab-height: 3.2rem;
$tab-cursor-height: 1.6rem;

$sidebar-width-small: 210px;
$sidebar-width-medium: 260px;
$sidebar-width-large: 350px;

$topbar-height: 3rem;

$footer-height: 5rem;

$main-content-max-width: 1150px;

$panel-max-width: 300px;

$post-extend-min-height: 35rem;
